import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';


// config file
const LogoutModal = ({ closeLogoutModal, openLogoutModal }) => {

    const clearLocalStorage = () => {
        let rememberMeDetails = localStorage.getItem('loginRememberInfo');
        localStorage.clear();
        if (rememberMeDetails) {
            localStorage.setItem('loginRememberInfo', rememberMeDetails)
        }
    }

    const logout = () => {
        closeLogoutModal('close');
        return fetchRequest('POST', apiCalls.logoutUser)
            .then(async (response) => {
                if (response && response.respCode && response.respCode == 200) {
                    clearLocalStorage();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            })
            .catch((err) => {
                showToasterMessage(err, 'error');
            });
    }


    return (
        <div>
            <Modal isOpen={openLogoutModal}
                className={`modal-dialog-centered modal-dialog--primary m-auto`}
                style={{ maxWidth: 400 }}>
                <ModalBody >
                    <div style={{ marginBottom: 10 }}>Are you sure want to Logout?</div>
                    <Button color="primary" outline onClick={closeLogoutModal}
                        className='deleteModalBtn marginRight'>No</Button>
                    <Button color="primary" outline onClick={logout}
                        className='deleteModalBtn'>Yes</Button>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default LogoutModal;