import React, { useEffect, useState, useRef, useContext } from 'react';

import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import fetchMethodRequest from '../../../config/service';
import { useForm, Controller } from 'react-hook-form';
import apiCalls from '../../../config/apiCalls';
import { GlobalContext } from '../../App/App';


export default function PatientBar() {
    const [hspData, setHspData] = useState(localStorage.HospitalData ? JSON.parse(localStorage.getItem('HospitalData')) : null);


    let { handleSubmit, register, watch, reset, setValue, getValues, formState: { errors }, control, } = useForm();

    const context = useContext(GlobalContext);

    useEffect(() => {

        if (hspData && hspData._id) {
            setValue('hospitalId', hspData);
        }

    }, []);





    const onChange = (value, item) => {
        context?.setHospitalData(value);
    }


    /**
         * 
         * @param {Object} item 
         * @returns 
         */
    let getAutoComplete = (item) => {

        return (
            <div className='patient-auto-complete col-2 px-2 d-flex'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            item={item}
                            fieldState={fieldState}
                            errors={errors}
                            screen={'PatientNavBar'}
                            searchApi={item.searchApi}
                            searchField={item.searchField}
                            filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                            placeholder={item.placeholder}
                            label={item.label}
                            onChange={onChange}
                            getValues={getValues}
                            watch={watch}
                            setValue={setValue}
                            disabled={item.disabled}
                        />)}
                />
            </div>
        )
    }


    return (
        <div className='patient-nav-bar-main'>
            {getAutoComplete({ name: 'hospitalId', populateValue: 'hospitalID', populateField: 'hospitalName', searchApi: apiCalls.hospitals, searchField: 'fullHospitalName', placeholder: 'Select a Practice', disableLabel: true, style: { height: '1.9rem', lineHeight: '1.9rem' }, value: hspData, clearable: true, localStorageField: 'HospitalData' })}
        </div>

    );
}
