import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import Editor from 'ckeditor5-custom-build'
// import Editor from '../../../../ckeditor/build/ckeditor.js'


const DoEditor = (props) => {
    const { field, errors, fieldState } = props;

    return <div>
        <CKEditor
            editor={ClassicEditor}
            data={field.value}
            onChange={(event, editor) => {
                const data = editor.getData();
                field.onChange(data);
            }}
        />
        <small className="text-danger ">{fieldState.invalid ? errors[field.name]?.message : ''}</small>
    </div>

}
export default DoEditor;