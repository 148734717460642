import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";

import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';


import { InputText } from 'primereact/inputtext';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as yup from "yup";
import { Link } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DoTextareaField from './Fields/DoTextareaField';
import DoSelectField from './Fields/DoSelectField';
import DoInputField from "./Fields/DoInputField";
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoRadioButtons from './Fields/DoRadioButtons';
import Loader from '../App/Loader';
import fetchMethodRequest from '../../config/service';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

let QuestionsFormModal = (props) => {
  const [inputFields, setInputFields] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  let [formType, setFormType] = useState(props.formType);
  const [questions, setQuestions] = useState(props.tableRowData.questions ? props.tableRowData.questions : []);
  let [editRowDataID, setEditRowDataID] = useState(props.tableRowData._id ? props.tableRowData._id : "");

  let [schema, setSchema] = useState();
  const [type,setType] = useState('Template');
  const [isOpenDialog,setIsOpenDialog] = useState(false);
  const [selectedType,setSelectedType] = useState({});
  const [hospitalIdDiasble,setHospitalIdDiasble] = useState(false);
  const [autoCompleteField,setAutoCompleteField] = useState(props.tableRowData);

  useEffect(() => {
    setSchema({resolver: yupResolver( yup.object().shape({
      name: yup.string().required('Name is required field'), // Example validation for a required string
      hospitalId: getValues && !getValues('defaultTemplate') ? yup.object().required('Practice ID is required field') : yup.object(), //validation for Hospital ID
      // Add more validation rules for other form fields if needed
    }))})
    if (props.formType === 'edit' || props.tableRowData._id) {
      setValue("name", props.formType === 'add' ? null : props.tableRowData.name);
      props.tableRowData.defaultTemplate ? setHospitalIdDiasble(true) : setValue("hospitalId", props.tableRowData.hospitalId);
      setValue("doctorSignature",props.tableRowData.doctorSignature?true:false);
      setValue("patientSignature",props.tableRowData.patientSignature?true:false);
      setValue("defaultTemplate",props.tableRowData.defaultTemplate && props.formType !== 'add');
      setValue("type",props.tableRowData.type);
      setType(props.tableRowData.type);
      setValue("templateText",props.tableRowData.templateText?props.tableRowData.templateText:"")
      if(props.formType === 'add'){
        setValue('copyTemplate',props.tableRowData);
      }
    }
    else if(props.formType === 'add'){
      setValue("doctorSignature",false);
      setValue("patientSignature",false);
      setValue("type","Template");
      setValue("defaultTemplate",false);
    }
    setValue('filledByFrontDesk',props.tableRowData?.filledByFrontDesk ? true :false);
  }, []);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm(schema);


   /**
   * 
   * @param {Object} obj 
   * @returns 
   */
   let submit = (obj) => {//When we submit the form.
    obj.questions = type == "Question" ? questions : [];
    obj.templateText = type == "Template" ? obj.templateText : "";
    obj.type = type;
    if(obj.defaultTemplate){
      delete obj.hospitalId;
    }
    const formFields = props.formFields(); // Assuming this gets the relevant form fields
    for (let key in autoCompleteField) {
        if (autoCompleteField.hasOwnProperty(key) && typeof autoCompleteField[key] === 'object') {
            const formField = formFields.find(field => field.id === key);
            if (formField && formField.searchField && autoCompleteField[key][formField.searchField]) {
                obj[`autoComplete_${key}`] = autoCompleteField[key][formField.searchField];
            }
        }
    }

    if (obj) {
      saveDataToServer(obj);
    } else {
      return;
    }
  }
  const onErrors = (data) => {
  }

  /**
   * 
   * @param {Object} e 
   */
  const typeChange = (e) => {
    setSelectedType(e.value);
    setIsOpenDialog(true);
  }

  let getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-column ">
            <label htmlFor={i} className="text-capitalize">{item.label}</label>
            <Dropdown
                value={field.value}
                optionLabel= 'label'
                placeholder={item.placeholder}
                name={field.name}
                options={item.options}
                focusInputRef={field.ref}
                onChange={typeChange}
                markReq={item.required}
            />
            <small className="text-danger ">{fieldState.invalid ? errors[field.name]?.message : ''}</small>
        </div>)
          }
        />
      </div>
    )
  }

  /**
   * 
   * @param {Number} i 
   * @param {Object} item 
   * @returns 
   */
  let getCkEditor = (i, item) => {//It Render the CkEditor Field
    let self = this;
    return (
      <div className='mt-3'>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <CKEditor
            editor={ClassicEditor}
            data={field.value ? field.value : ''}
            onChange={(event, editor) => {
                const data = editor.getData();
                field.onChange(data);
            }}
            />
              <small className="text-danger ">{fieldState.invalid ? errors[field.name]?.message : ''}</small>
            </div>
            
          )}
        />
      </div>
  
      // <span>Testing</span>
    )
  }

  /**
   * 
   * @param {Number} i 
   * @param {Object} item 
   * @returns 
   */
  let getDefault = (i, item) => {//It Render the Default input Field
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
              markReq={item.required}
            />)}
        />
      </div>
    )
  }

  const handleBackButtonClick = () => {//When we click on the Form Cancel Button
    window.location.href = `/${props.routeTo}`;
  };

  let getButtonToolbar = () => {//Render the Form buttons
    let { t } = props;
    return (
      <div className='d-flex mt-5'>
        <div className='col-12 px-0'>
          <span className='float-end'>

            <Button color='primary' type="button" onClick={handleBackButtonClick}>
              {t('Back') ? t('Back') : 'Back'}
            </Button>
            <Button color='primary'  type="submit"
              // onClick={handleBackButtonClick}
            >
              {formType && formType === 'add' ?
                t('Save') ? t('Save') : 'Save'
                : t('Update') ? t('Update') : 'Update'
              }
            </Button>
          </span>
        </div>
      </div>
    )
  }

  /**
   * 
   * @param {Number} i 
   * @param {Object} item 
   * @returns 
   */
  let getAutoComplete = (i, item) => {//Render AutoComplete Field
    const itemTemplate = (option) => {

      return (
        <div className="autocomplete-item">
          <div>{`${option.hospitalID} (${option.hospitalName})`}</div>
        </div>
      );
    };

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              searchApi="hospitals"
              itemTemplate={itemTemplate}
              searchField="hospitalID"
              formType={props.formType}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              setValue={setValue}
            />)}
        />
      </div>
    )
  }

  /**
   * 
   * @param {Number} i 
   * @param {Object} item 
   * @returns 
   */
  let getRadio = (i, item) => {//Render Radio Field
    return (
      <div key={i}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              options={item.options}
              input={field}
              id={field.id}
              name={field.name}
              field={field}   
              item={item}           
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              value={item.value}
              onChange={item.onChange}
            />)}
        />
      </div>
    )
  }

  let clearFormFields = async () => {//It Clear the Form field Values
    if (props.load) {
      props.load({});
    }
    let formData = [...formFields];
    formData.forEach((item) => {
      item.value = '';
      item.invalid = false;
      item.message = ''
    });
    await setFormFields(formData)
    getFormFields();
  }

   /**
   * 
   * @param {Object} rowData 
   * @param {String} type 
   */
   let handleViewDisplay = async (rowData, type) => {//To Display the data on the View formate
    await props.getDataFromServer(props.filterCriteria)
    let _id = rowData['_id'];
    let rowDataIndex = getUserData(rowData['_id'])
    let keys = Object.keys(rowData);
    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          keys.forEach(async function (key) {
            let labelKey = await getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }

      setFormType('view');
      setUserData(rowData);
      setDisplayBreadCrumbValue(rowData[displayBreadCrumbField]);
      setUserStatus(rowData['status'])
      await getViewData(formFields, 'view', rowDataIndex, rowData, _id);
    }
  }

   /**
   * 
   * @param {Object} userBody 
   * @returns 
   */
   let saveDataToServer = async (userBody) => {//Save the Data To the Server
    let validationExists;
    if (!validationExists) {
      if (questions) {
        let method, apiUrl;

        if (formType === 'edit') {
          delete userBody.email;
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else if (formType === 'view') {
          delete userBody.email;
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else {
          method = 'POST';
          apiUrl = props.apiUrl;
        }

        return fetchMethodRequest(method, apiUrl, userBody)
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired');
            if (sessionexpired === "true") {
              setSessionExpiryModal(true);
            }
            if (response && response.respCode) {
              await props.getDataFromServer(props.filterCriteria);
              showToasterMessage(response.respMessage, 'success');
              if (props.displayViewOfForm === 'modal') {
                props.closeFormModal('save', response.quantityId);
              } else {
                if (formType !== 'add') {
                  window.location.href = `/${props.routeTo}`;
                } else {
                  props.closeFormModal('save', response.quantityId);
                }
              }
              clearFormFields();
              props.reset();
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, 'error');
            }
            setIsLoading(false);
          }).catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  };

/**
   * 
   * @param {Number} index 
   * @param {String} fieldName 
   * @param {Object} e 
   */
let handleChange = (index, fieldName, e) => {//Question OnChange Function
    let newFormValues = [...questions];
    newFormValues[index][fieldName] = fieldName == "Required" ? !questions[index].Required : e.target.value;
    setQuestions(newFormValues);
  };


  let addFormFields = () => {//Add the new question into the Questions array
    setQuestions([...questions, { Question: "", FieldType: "", Options: "", Required: false }])
  }

   /**
   * 
   * @param {Number} index 
   */
   let removeFormFields = (index) => {//Remove a Question from the Questions Array
    let newFormValues = [...questions];
    newFormValues.splice(index, 1);
    setQuestions(newFormValues)
  }


  const fieldtypes = [
    { label: 'CheckBox', value: 'CheckBox' },
    { label: 'Radio', value: 'Radio' },
    { label: 'TextArea', value: 'TextArea' },
    { label: 'Input', value: 'Input' },
    { label: 'Dropdown', value: 'Dropdown' },
  ];

  const onChangeInputFields = (value) => {
    setHospitalIdDiasble(value)
    if(value){
      setValue("hospitalId",undefined)
      setSchema({resolver: yupResolver( yup.object().shape({
        name: yup.string().required('Name is required field')
      }))})
    }
    else{
      setSchema({resolver: yupResolver( yup.object().shape({
        name: yup.string().required('Name is required field'),
        hospitalId: yup.object().required("Practice ID is Required field")
      }))})
    }
  }

  let closeFormModal = async () => {
    clearFormFields();
    props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let getHeader = () => {
    let { t } = props;

    if (props.displayViewOfForm != "sideForm") {
      const type = props.displayName ? props.displayName : props.type;
      const headerType = props.customHeader ? props.customHeader : (formType === "add" ? `Add ${type}` : formType === "view" ? `View ${type}` : `Edit ${type}`);
      return (
        <div className='d-flex form_form-header mb-3'>
          <div className='col-12 px-0 pb-1'>
            <span className='float-left pt-2'>
              <h4 style={{ textTransform: 'capitalize' }}><b>
                <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={closeFormModal} className='text-white px-1'>
                  {headerType}
                </Link>
              </b> </h4>
            </span>
          </div>
        </div>
      )
    }
  }

  const renderInputFields = () => {//Question Render Input Fields
    return (
      <div>  
        <div className='d-flex flex-wrap' style={{width:type == "Question"?'93%':'100%'}}>
          <div className='col-4 field'>
            {getDefault(0, { name: 'name', type: 'text', placeholder:"Name" ,  label: 'Name' , required:true})}
          </div>
          <div className='col-4 field'>
            {getAutoComplete(1, { name: 'hospitalId', type: 'text',placeholder:"Select a Practice ID" ,  label: 'Practice ID' ,diasble: hospitalIdDiasble,searchField:'fullHospitalName',required :!getValues('defaultTemplate'),populateValue:'hospitalID',populateField:'hospitalName'})}
          </div>
          <div className='col-4 field' 
          // style={{width : "300px"}}
          >
            {getDropdown(1, { name: 'type', type: 'text', label: 'Type',options:[{label: "Question", value: "Question"},{label :"Template" , value :"Template"}] })}
          </div>
          <div className='col-3 field mt-3'>
            {getRadio(0, { name: 'defaultTemplate', type: 'radio', label: 'Default Template Required', value:false ,onChange:onChangeInputFields ,options: [{label:'Yes',value:true},{label:"No",value:false}]})}
          </div>
          <div className='col-3 field mt-3'>
            {getRadio(0, { name: 'doctorSignature', type: 'radio', label: 'Provider Signature Required', value:false ,options: [{label:'Yes',value:true},{label:"No",value:false}]})}
          </div>
          <div className=' col-3 field mt-3'>
            {getRadio(0, { name: 'patientSignature', type: 'radio', label: 'Patient Signature Required', value:false ,options: [{label:'Yes',value:true},{label:"No",value:false}]})}
          </div>
          <div className='col-3 field mt-3'>
            {getRadio(0, { name: 'filledByFrontDesk', type: 'radio', label: 'Filled by Front Desk', value:false ,options: [{label:'Yes',value:true},{label:"No",value:false}]})}
          </div>
        </div>
        {type == "Question" && questions.map((element, index) => (
          <div className='total-container' key={index}>
            <div className="horizontal-container">
              <label>Question</label>
              <InputTextarea
                name="Question"
                value={element.Question || ""}
                onChange={(e) => handleChange(index, "Question", e)}
              />
              <label>Field Type</label>
              <Dropdown
                name="Field Type"
                value={element.FieldType || ""}
                onChange={(e) => handleChange(index, "FieldType", e)}
                options={fieldtypes}
              >

              </Dropdown>
              <label>Options</label>
              <InputText name="Options"
                value={element.Options || ""}
                onChange={(e) => handleChange(index, "Options", e)}
              />

              <label>Required</label>
              <Checkbox onChange={(e) => handleChange(index, "Required", e)} checked={questions[index].Required} />
            </div>

            <div>

            <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='trash-alt'
              data-toggle="tool-tip"
              title="Delete"
              style={{ color: '#bf1725',  fontSize: '25px', marginTop: "5px", cursor: 'pointer' ,}}
              onClick={(e) => {
                e.preventDefault();
                removeFormFields(index);
              }}
              />

            </div>
          </div>
        ))
        }
        {type == "Template" && getCkEditor(1,{name:'templateText'})}
      </div>
    )
  }

  let getScreenBody = () => {//Render Screen Form
    return (
      <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
        <Loader loader={isLoading} />
        {getHeader()}
        <div className='questions-body'>
          <div>
            {renderInputFields()}

            <div>
              {getButtonToolbar()}
            </div>

            {type == "Question" && <Button color='primary'
              onClick={(e) => {
                e.preventDefault();
                addFormFields();
              }}
              className="top-right-button"
            >
              Add Question
            </Button>}
          </div>
        </div>
      </form>
    );
  };

  

  const closeDeleteDialog = () =>{//Close the Delete Modal
    setIsOpenDialog(false);
    setValue("type",selectedType);
    setType(selectedType)
    if(type== "Template"){
      setValue('templateText','');
    }else if(type == "Question"){
      setQuestions([]);
    }
  }

  const getDeleteDialog = () =>{//Render the Delete Modal

    return <Modal isOpen={isOpenDialog} className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'>
    <ModalBody className='deleteModalBody'>
        <div style={{ marginBottom: 10 }}>Do you want to delete the Present {type} data </div>
        <Button color="primary" outline onClick={() => {setIsOpenDialog(false)}} className='deleteModalBtn marginRight'>No</Button>
        <Button color="primary" outline onClick={closeDeleteDialog} className='deleteModalBtn'>Yes</Button>
    </ModalBody>
</Modal>

  }

  
  let { displayViewOfForm } = props;
  return (
    <div>
      {getScreenBody()}
      {getDeleteDialog()}
    </div>
  );
}


export default withTranslation('common')(QuestionsFormModal);



