import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
const DeleteFilterModal = (props) => {

    console.log("DeleteFilterModal", props)
    const handleNo = () => {
        props.deleteSelectedFilter('no');
        props.setDeleteFilterModal(false);
    }
    const handleYes = () => {
        props.deleteSelectedFilter('yes');
    }

    return (
        <div>
            <Modal isOpen={props.deleteFilterModal}
                className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
            >
                <ModalBody className='deleteModalBody'>
                    <div style={{ marginBottom: 10 }}>Are you sure want to Delete?</div>

                    <Button color="primary" outline onClick={() => handleNo()} className='deleteModalBtn marginRight'>No</Button>
                    <Button color="primary" outline onClick={() => handleYes()} className='deleteModalBtn'>Yes</Button>
                </ModalBody>
            </Modal>
        </div>
    );
}
export default DeleteFilterModal;