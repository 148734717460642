import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Clinics from './components/Clinics';

const clinics = (props,{ t }) => (
  <Container>
    <Clinics {...props} />
  </Container>
);

clinics.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(clinics);
