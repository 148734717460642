import React, { useEffect, useState } from 'react';
import { Scrollbar } from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";
import apiCalls from '../../../config/apiCalls';

const Sidebar = (props) => {

  const [menuList, setMenuList] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({});



  useEffect(() => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      setLoginCredentials(loginCredentials);
    }
    getMenuListFromServer()
    return () => {

    };
  }, []);

  const getMenuListFromServer1 = async () => {
    let menuList = [{
      "icon": "employee",
      "displayTitle": "Employees",
      "title": "Employee",
      "route": "/employee"
    }, {
      "icon": "activities",
      "displayTitle": "Activitiess",
      "title": "Activities",
      "route": "/activities"
    }, {
      "icon": "email templates",
      "displayTitle": "Email Templatess",
      "title": "Email Templates",
      "route": "/email templates"
    }, {
      "icon": "roles",
      "displayTitle": "Roless",
      "title": "Roles",
      "route": "/roles"
    }, {
      "icon": "upload history",
      "displayTitle": "Upload Historys",
      "title": "Upload History",
      "route": "/upload history"
    }, {
      "icon": "email status",
      "displayTitle": "Email Statuss",
      "title": "Email Status",
      "route": "/email status"
    }, {
      "icon": "users",
      "displayTitle": "Userss",
      "title": "Users",
      "route": "/users"
    }, {
      "icon": "hospitals",
      "displayTitle": "Hospitalss",
      "title": "Hospitals",
      "route": "/hospitals"
    }, {
      "icon": "clinics",
      "displayTitle": "Clinicss",
      "title": "Clinics",
      "route": "/clinics"
    }, {
      "icon": "patients",
      "displayTitle": "Patientss",
      "title": "Patients",
      "route": "/patients"
    }, {
      "icon": "templates",
      "displayTitle": "Templatess",
      "title": "Templates",
      "route": "/templates"
    }, {
      "icon": "forms",
      "displayTitle": "Formss",
      "title": "Forms",
      "route": "/forms"
    }, {
      "icon": "schedules",
      "displayTitle": "Scheduless",
      "title": "Schedules",
      "route": "/schedules"
    }, {
      "icon": "chairs",
      "displayTitle": "Chairss",
      "title": "Chairs",
      "route": "/chairs"
    }, {
      "icon": "appointments",
      "displayTitle": "Appointmentss",
      "title": "Appointments",
      "route": "/appointments"
    }, {
      "icon": "procedures",
      "displayTitle": "Proceduress",
      "title": "Procedures",
      "route": "/procedures"
    }, {
      "icon": "tx plan",
      "displayTitle": "Tx Plans",
      "title": "Tx Plan",
      "route": "/tx plan"
    }, {
      "icon": "treatments",
      "displayTitle": "Treatmentss",
      "title": "Treatments",
      "route": "/treatments"
    }, {
      "icon": "prescriptions",
      "displayTitle": "Prescriptionss",
      "title": "Prescriptions",
      "route": "/prescriptions"
    }, {
      "icon": "pharmacies",
      "displayTitle": "Pharmaciess",
      "title": "Pharmacies",
      "route": "/pharmacies"
    }, {
      "icon": "treatment plan",
      "displayTitle": "Treatment Plans",
      "title": "Treatment Plan",
      "route": "/treatment plan"
    }, {
      "icon": "drugs",
      "displayTitle": "Drugss",
      "title": "Drugs",
      "route": "/drugs"
    }, {
      "icon": "reasons",
      "displayTitle": "Reasonss",
      "title": "Reasons",
      "route": "/reasons"
    }, {
      "icon": "insurance",
      "displayTitle": "Insurances",
      "title": "Insurance",
      "route": "/insurance"
    }, {
      "icon": "carriers",
      "displayTitle": "Carrierss",
      "title": "Carriers",
      "route": "/carriers"
    }, {
      "icon": "benifits",
      "displayTitle": "Benifitss",
      "title": "Benifits",
      "route": "/benifits"
    }, {
      "icon": "feeschedules",
      "displayTitle": "Feescheduless",
      "title": "Feeschedules",
      "route": "/feeschedules"
    }, {
      "icon": "patienteligibility",
      "displayTitle": "PatientEligibilitys",
      "title": "PatientEligibility",
      "route": "/patienteligibility"
    }, {
      "icon": "releasenotes",
      "displayTitle": "ReleaseNotess",
      "title": "ReleaseNotes",
      "route": "/releasenotes"
    }, {
      "icon": "procedureslist",
      "displayTitle": "ProceduresLists",
      "title": "ProceduresList",
      "route": "/procedureslist"
    }, {
      "icon": "chartprocedures",
      "displayTitle": "ChartProceduress",
      "title": "ChartProcedures",
      "route": "/chartprocedures"
    }, {
      "icon": "clockin and clockout",
      "displayTitle": "ClockIn And ClockOuts",
      "title": "ClockIn And ClockOut",
      "route": "/clockin and clockout"
    }, {
      "icon": "available prompts",
      "displayTitle": "Available Promptss",
      "title": "Available Prompts",
      "route": "/available prompts"
    }, {
      "icon": "auto notes",
      "displayTitle": "Auto Notess",
      "title": "Auto Notes",
      "route": "/auto notes"
    }, {
      "icon": "chart  history",
      "displayTitle": "Chart  Historys",
      "title": "Chart  History",
      "route": "/chart  history"
    }, {
      "icon": "upload teeth images",
      "displayTitle": "Upload Teeth Imagess",
      "title": "Upload Teeth Images",
      "route": "/upload teeth images"
    }, {
      "icon": "patient notes",
      "displayTitle": "Patient Notess",
      "title": "Patient Notes",
      "route": "/patient notes"
    }, {
      "icon": "referral",
      "displayTitle": "Referrals",
      "title": "Referral",
      "route": "/referral"
    }, {
      "icon": "accounts",
      "displayTitle": "Accountss",
      "title": "Accounts",
      "route": "/accounts"
    }, {
      "icon": "makepayments",
      "displayTitle": "MakePaymentss",
      "title": "MakePayments",
      "route": "/makepayments"
    }, {
      "icon": "image maintaining",
      "displayTitle": "Image Maintainings",
      "title": "Image Maintaining",
      "route": "/image maintaining"
    }, {
      "icon": "perio chart",
      "displayTitle": "Perio Charts",
      "title": "Perio Chart",
      "route": "/perio chart"
    }, {
      "icon": "payments",
      "displayTitle": "Paymentss",
      "title": "Payments",
      "route": "/payments"
    }, {
      "icon": "claim management",
      "displayTitle": "Claim Managements",
      "title": "Claim Management",
      "route": "/claim management"
    }];
  }

  const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }

  const getMenuListFromServer = async () => {
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `menus?filter=${JSON.stringify(filterCriteria)}`;
      return fetch('GET', url)
        .then(async (res) => {
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.map(sitem => {
                      keys.forEach(element => {
                        if (camelizedString(sitem.displayTitle) === camelizedString(element)) {
                          if (rolePermissions[element].type !== "NoView") {
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(element => {
                      if (camelizedString(item.displayTitle) === camelizedString(element)) {
                        if (rolePermissions[element].type !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }

              setMenuList(neWmenuList);
            }
          }
        })

    }
  }


  const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = props;
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onClick={() => { }}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            menuList={menuList}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            menuList={menuList}
          />
        </div>
      </Scrollbar>
    </div>
  );
};


Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;