import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Patients from './components/Patients';

const patients = (props,{ t }) => (
  <Container>
    <Patients {...props} />
  </Container>
);

patients.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(patients);
